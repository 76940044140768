const USER_LOGIN = `query courses($email: String, $password: String) {
  login(email: $email, password: $password) {
    email
    fullname
    mobile
    plan_type
    main_account
    accessToken
    idToken
    refreshToken
  }
}
`

const SUBSCRIBER_QUERY = `query subscriber($email: String!) {
  subscriber(email: $email) {
    _id
    email
    mobile
    plan_type
    main_account
    fullname
    company
    designation
    country
    city
    language
    profile
    stw_vision
    stw_outcome
    favourite_categories
    tm_subscription
    tm_registered
  }
}
`

export {
  USER_LOGIN,
  SUBSCRIBER_QUERY
}
