import React, { memo, useState } from 'react'
import { 
  NavLink,
  // useHistory
} from 'react-router-dom'
import { useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Container,
  CssBaseline,
  Typography
} from '@material-ui/core'
import {
  LockOutlined
} from '@material-ui/icons'
import { USER_RESET_PASSWORD_MUTATION } from '@gql/user'
import { ResetPassword } from '@common/components'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  }
}))

const Reset = () => {
  const styles = useStyles()
  // const history = useHistory()
  const [userResetPassword] = useMutation(USER_RESET_PASSWORD_MUTATION)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState('')
  const [reset, setReset] = useState(false)
  const [values, setValues] = useState({
    email: '',
    code: '',
    password: ''
  })

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const userTriggerResetPassword = () => {
    const { email, code, password } = values
    setLoading(true)

    userResetPassword({
      variables: {
        email,
        code,
        password
      }
    }).then( ({data, error}) => {
      if (data?.resetPassword === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setLoading(false)
        return
      }

      setLoading(false)
      setReset(true)
      // history.push('/login')
    })
  }

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <div className={styles.paper}>
          <Avatar className={styles.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">Reset Password</Typography>
          { reset ? <Typography variant="body2" align="center">Password reset successful. Please <NavLink to="/login" variant="body2">login</NavLink> with you new password</Typography> : 
          <ResetPassword handleInputChange={handleInputChange} handleChange={handleChange} userTriggerResetPassword={userTriggerResetPassword} handleClickShowPassword={handleClickShowPassword} messages={messages} loading={loading} values={values} showLogin={true} requestCode="forgot" />}
        </div>
      </Container>
    </div>
  )
}

export default memo(Reset)
