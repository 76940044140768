import React, { memo, useState, useEffect } from 'react'
import { useMutation } from 'graphql-hooks'
import { NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@material-ui/core'
import {
  LockOutlined,
  Visibility,
  VisibilityOff
} from '@material-ui/icons'
import {
  SnackBar,
  Spinner
} from '@common/components'
import { USER_LOGIN_MUTATION } from '@gql/user'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 64px)',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '0',
    outline: 'none',

    '& fieldset': {
      border: 'none'
    },

    '& input:focus, &:hover fieldset': {
      outline: 'none !important',
      border: 'none'
    },

    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  margin: {
    marginBottom: theme.spacing(2),
  }
}));

const Login = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector(state => state.user)
  const [userLogin] = useMutation(USER_LOGIN_MUTATION)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState('')
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false
  })

  useEffect(() => {
    if (user?.accessToken) history.push('/browse')
  },[user, history])

  const userSubmitLogin = () => {
    setLoading(true)
    const { email, password } = values

    userLogin({
      variables: {
        email,
        password
      }
    }).then( ({data, error}) => {
      if (data?.login === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        setOpen(true)
        setLoading(false)
        return
      }
      
      setLoading(false)
      setMessages('Please check your email and verify your registration')
      dispatch({
        type: 'USER_LOGIN_SUCCESS',
        payload: data.login
      })
      history.push('/browse')
    })
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const handleClose = (event, reason) => {
    setOpen(false)
  }

  return (
    <div className={styles.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={styles.paper}>
          <Avatar className={styles.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">Sign in</Typography>
          <form className={styles.form} noValidate>
            <FormControl variant="filled" fullWidth className={styles.margin}>
              <TextField
                variant="filled"
                required              
                label="Email Address"
                name="email"
                autoComplete="off"
                autoFocus
                onChange={handleChange('email')}
                className={styles.textField}
              />
            </FormControl>
            <FormControl variant="filled" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="email"
                name="email"
                type={values.showPassword ? 'text' : 'password'}
                onChange={handleChange('password')}
                value={values.password}
                autoComplete="off"
                className={styles.textField}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            {loading ? <Spinner /> : <Button fullWidth variant="contained" color="primary" className={styles.submit} onClick={() => userSubmitLogin()}>Sign In</Button>}            
            <Grid container>
              <Grid item xs>
                <NavLink to="/forgot" variant="body2">
                  {"Forgot password?"}
                </NavLink>
              </Grid>
              <Grid item>
                <NavLink to="/signup/account" variant="body2">
                  {"Don't have an account? Sign Up"}
                </NavLink>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>

      <SnackBar messages={messages} open={open} close={handleClose} />
    </div>
  )
}

export default memo(Login);