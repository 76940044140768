import React, { memo, useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import { SUBSCRIBER_QUERY, USER_UPDATE_ACCOUNT_MUTATION, USER_CANCEL_SUBSCRIPTION } from '@gql/user'
import { CommonStyle, UserProfileStyle } from '@common/styles'
import { nextSubscrption, formatJoinDate } from '@helpers'
import { SnackBar } from '@common/components'

const useStyles = makeStyles((theme) => ({
  memberSince: {
    padding: '4px 12px',
    margin: '0 16px',
    backgroundColor: '#313131',
    borderRadius: '4px'
  },
  button: {
    backgroundColor: '#e50914',
    color: '#fff',

    "&:hover": {
      backgroundColor: '#e50914'
    }
  }
}))

const UserProfile = () => {
  const styles = useStyles()
  const Common = CommonStyle()
  const ProfileStyle = UserProfileStyle()
  const dispatch = useDispatch()
  const [getSubscriber] = useManualQuery(SUBSCRIBER_QUERY)
  const [cancelSubscription] = useMutation(USER_CANCEL_SUBSCRIPTION)
  const [updateAccount] = useMutation(USER_UPDATE_ACCOUNT_MUTATION)
  const user = useSelector(state => state.user.user)
  const [subscriber, setSubscriber] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const [messages, setMessages] = useState(null)

  const fetchSubscriber = useCallback(() => {
    getSubscriber({
      variables: {
        email: user.email
      }
    }).then( ({data, error}) => {
      if(error) {
        console.log(error)
      } else {
        setSubscriber(data.subscriber)
      }
    })
  }, [getSubscriber, user.email])

  useEffect(() => {
    fetchSubscriber()
  }, [fetchSubscriber])

  const userCancelSubscription = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleUserCancelSubscription = () => {
    cancelSubscription({
      variables: {
        email: user.email,
      }
    }).then( ({data, error}) => {
      if (data?.cancelSubscription === null) {
        const { message } = error.graphQLErrors[0]
        setMessages(message)
        return
      }

      // if success, we need to update the subscriber plan_type
      updateAccount({
        variables: {
          email: user.email,
          profile: {
            plan_type: 'free'
          }
        }
      }).then( ({data, error}) => {
        if (data?.upgradeAccount === null) {
          const { message } = error.graphQLErrors[0]
          setMessages(message)
          return
        }

        // We also need to update the plan_type to 'premium' of logged in user information in the local storage
        const updateLocalUser = {
          ...user,
          plan_type: 'free'
        }
        dispatch({
          type: 'USER_ACCOUNT_UPGRADE_SUCCESS',
          payload: updateLocalUser
        })
      })

      setMessages('Subscription is successfully cancel.')
      setModalOpen(false)
      window.location.reload()
    })
  }

  const renderSubscriptionButton = () => {
    if (subscriber && subscriber?.plan_type === 'free') {
      return <Button component={NavLink} to="/user/upgrade/subscription" variant="outlined" color="primary" size="small">Upgrade plan</Button>
    } else {
      return <Button variant="outlined" color="primary" size="small" onClick={() => userCancelSubscription()}>Cancel subscription</Button>
    }
  }

  const renderPlanInfo = () => {
    if (subscriber && subscriber?.plan_type === 'free') {
      return ''
    } else {
      return `Your next billing date is ${nextSubscrption(subscriber?.tm_subscription)}`
    }
  }

  return (
    <div>
      <Container className={Common.content}>
        <Grid container className={Common.body}>
          <Grid container direction="row" alignItems="center">
            <Typography variant="h5">Account</Typography>
            <Typography variant="caption"><span className={ProfileStyle.memberSince}>Member since {formatJoinDate(subscriber?.tm_registered)}</span></Typography>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={4}><Typography variant="subtitle1">Profile Information</Typography></Grid>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.email}</Typography></Grid>
              <Grid item xs={4}></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">Password: ******</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption"><Button component={NavLink} to="/user/update/password" variant="outlined" color="primary" size="small">Change password</Button></Typography></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.mobile ? `Phone: ${subscriber?.mobile}` : 'You have not update your contact number'}</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption"><Button component={NavLink} to="/user/update/mobile" variant="outlined" color="primary" size="small">Change phone number</Button></Typography></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{`${subscriber?.city}, ${subscriber?.country}`}</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption"></Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={4}><Typography variant="subtitle1">Company</Typography></Grid>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.designation}</Typography></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.company}</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={4}><Typography variant="subtitle1">Short Profile</Typography></Grid>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.profile}</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={4}><Typography variant="subtitle1">Personal Vision</Typography></Grid>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.stw_vision}</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={4}><Typography variant="subtitle1">Outcome</Typography></Grid>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.stw_outcome}</Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />

        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={4}><Typography variant="subtitle1">Plan details</Typography></Grid>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{subscriber?.plan_type}</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption">{renderSubscriptionButton()}</Typography></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">{renderPlanInfo()}</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption"></Typography></Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider className={Common.divider} />
        {/*
        <Grid container spacing={4} className={Common.section}>
          <Grid item xs={4}><Typography variant="subtitle1">Family members</Typography></Grid>
          <Grid item xs={8}>
            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">Family member 1</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption">Disable | Delete</Typography></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">Family member 2</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption">Disable | Delete</Typography></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Typography variant="body2">Family member 3</Typography></Grid>
              <Grid item xs={4}><Typography variant="caption">Disable | Delete</Typography></Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={8}><Button variant="contained" className={styles.button}>+ Add new family member (SGD 9.90 per member)</Button></Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </Grid>
        </Grid>
        */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Button component={NavLink} to="/user/update/profile" fullWidth variant="contained" color="primary" className={styles.raleway}>Update Profile</Button>
          </Grid>
        </Grid>
      </Container>

      <Dialog open={modalOpen} onClose={handleModalClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Cancel subscription</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to cancel subscription?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">Cancel</Button>
          <Button onClick={handleUserCancelSubscription} color="primary" autoFocus>Confirm</Button>
        </DialogActions>
      </Dialog>

      <SnackBar messages={messages} open={open} close={handleClose} />
    </div>
  )
}

export default memo(UserProfile)