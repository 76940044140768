import { Home, Login, Register, ForgotPassword, ResetPassword, UpdateProfile } from '@common'
import { SignUpMain, SignUpAccount, SignUpCategories, SignUpPlan, SignUpCheckout } from '@signup'
import { BrowseList } from '@browse'
import { DiscoverList } from '@discover'
import { ClassList, ClassSingle, ClassQuiz, ClassCertificate } from '@classes'
import { MyclassList, MyclassSingle } from '@myclasses'
import { WebinarsList,JoinWebinar } from '@webinars'
import { UserProfile, UserProfileUpdate, UserMobileUpdate, UserPasswordUpdate, UserPasswordReset, UserUpgradeSubscription } from '@user'
import { InstructorProfile } from '@instructors'
import { AboutConsulusClass, MembershipPlan, ToolkitForContentCreators, ConsulusIpsCopyrights } from '@pages'

const routes = [
  {
    path: '/',
    component: Home,
    auth: false,
    exact: true,
    header: false,
    footer: false
  }, {
    path: '/login',
    component: Login,
    auth: false,
    exact: true,
    header: false,
    footer: false
  }, {
    path: '/register',
    component: Register,
    auth: false,
    exact: true,
    header: false,
    footer: false
  }, {
    path: '/forgot',
    component: ForgotPassword,
    auth: false,
    exact: true,
    loginheader: false,
    header: false,
    footer: false
  }, {
    path: '/update',
    component: UpdateProfile,
    auth: true,
    exact: true,
    loginheader: false,
    header: true,
    footer: true
  }, {
    path: '/reset',
    component: ResetPassword,
    auth: false,
    exact: true,
    loginheader: false,
    header: false,
    footer: false
  }, {
    path: '/signup',
    component: SignUpMain,
    auth: false,
    exact: true,
    header: false,
    footer: true
  }, {
    path: '/signup/account',
    component: SignUpAccount,
    auth: false,
    exact: true,
    header: false,
    footer: true
  }, {
    path: '/signup/categories',
    component: SignUpCategories,
    auth: false,
    exact: true,
    header: false,
    footer: true
  }, {
    path: '/signup/plan',
    component: SignUpPlan,
    auth: false,
    exact: true,
    header: false,
    footer: true
  }, {
    path: '/signup/checkout',
    component: SignUpCheckout,
    auth: false,
    exact: true,
    header: false,
    footer: true
  }, {
    path: '/browse',
    component: BrowseList,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/class/:classId',
    component: ClassSingle,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/class/:classId/quiz',
    component: ClassQuiz,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/class/:classId/certificate',
    component: ClassCertificate,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/discover',
    component: DiscoverList,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/class',
    component: ClassList,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/class/test/:class',
    component: ClassSingle,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/my-class',
    component: MyclassList,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/my-class/:class',
    component: MyclassSingle,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/webinars',
    component: WebinarsList,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/webinar/:webinarId',
    component: JoinWebinar,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, 
  {
    path: '/instructor/:instructorId',
    component: InstructorProfile,
    auth: true,
    exact: true,
    header: true,
    footer: true
  },
  {
    path: '/user/profile',
    component: UserProfile,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/user/update/profile',
    component: UserProfileUpdate,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/user/update/mobile',
    component: UserMobileUpdate,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/user/update/password',
    component: UserPasswordUpdate,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/user/update/password-reset',
    component: UserPasswordReset,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/user/upgrade/subscription',
    component: UserUpgradeSubscription,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/about-consulus-class',
    component: AboutConsulusClass,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/membership-plan',
    component: MembershipPlan,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/toolkit-for-content-creators',
    component: ToolkitForContentCreators,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }, {
    path: '/consulus-ips-copyrights',
    component: ConsulusIpsCopyrights,
    auth: true,
    exact: true,
    header: true,
    footer: true
  }
]

export {
  routes
}
