import React, { memo, useState, useEffect, useCallback } from 'react'
import { 
	useSelector,
	// useDispatch
} from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { makeStyles } from '@material-ui/core/styles'
import {
	Button,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	TextField,
	Typography
} from '@material-ui/core'
import { SUBSCRIBER_QUERY, USER_UPDATE_PROFILE_MUTATION } from '@gql/user'
import { Spinner } from '@common/components'
import { CommonStyle, FormStyle, UserProfileStyle } from '@common/styles'

const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: '#e50914',
		color: '#fff',

		"&:hover": {
			backgroundColor: '#e50914'
		}
	}
}))

const UserProfileUpdate = () => {
	const styles = useStyles()
	const Common = CommonStyle()
	const Form = FormStyle()
	const UserProfile = UserProfileStyle()
	const history = useHistory()
	// const dispatch = useDispatch()
	const [getSubscriber] = useManualQuery(SUBSCRIBER_QUERY)
	const [updateProfile] = useMutation(USER_UPDATE_PROFILE_MUTATION)
	const user = useSelector(state => state.user)
	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	// const [messages, setMessages] = useState('')
	const [values, setValues] = useState({
		email: '',
		fullname: '',
		mobile: '',
		plan_type: '',
		main_account: '',
		company: '',
		designation: '',
		country: '',
		city: '',
		language: '',
		profile: '',
		stw_vision: '',
		stw_outcome: '',
		favourite_categories: [],
	})

	const fetchSubscriber = useCallback(() => {
		getSubscriber({
			variables: {
				email: user.user.email
			}
		}).then( ({data, error}) => {
			setValues(data.subscriber)
		})
	}, [getSubscriber, user.user.email])

	useEffect(() => {
		fetchSubscriber()
	}, [fetchSubscriber])

	const handleInputChange = e => {
		const {name, value} = e.target
		setValues({...values, [name]: value})
	}

	const handleCancel = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleUserLogout = () => {
		history.push('/user/profile')
	}

	const userUpdateProfile = () => {
		const {
			fullname,
			company,
			designation,
			country,
			city,
			language,
			profile,
			stw_vision,
			stw_outcome
		} = values

		setLoading(true)

		updateProfile({
			variables: {
				email: user.user.email,
				profile: {
					fullname,
					company,
					designation,
					country,
					city,
					language,
					profile,
					stw_vision,
					stw_outcome
				}
			}
		}).then( ({data, error}) => {
			if (data?.register === null) {
        // const { message } = error.graphQLErrors[0]
        // setMessages(message)
        setLoading(false)
        return
      }

      setLoading(false)
      history.push('/user/profile')
    })
	}

	return (
		<div>
		<Container className={Common.content}>
		<Grid container className={Common.body}>
		<Grid container direction="row" alignItems="center">
		<Typography variant="h5">Account</Typography>
		<Typography variant="caption"><span className={UserProfile.memberSince}>Member since 01 Jan 2020</span></Typography>
		</Grid>
		</Grid>

		<Divider className={Common.divider} />

		<Grid container spacing={4} className={Common.section}>
		<Grid item xs={12} md={4}><Typography variant="subtitle1">Profile Information</Typography></Grid>
		<Grid item xs={12} md={8}>
		<Grid container spacing={3}>
		<Grid item xs={12} md={6}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		id="fullname"
		placeholder="Full name *"
		name="fullname"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.fullname}
		/>
		</Grid>
		<Grid item xs={12} md={6}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		id="company"
		placeholder="Company *"
		name="company"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.company}
		/>
		</Grid>
		<Grid item xs={12} md={6}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		id="designation"
		placeholder="Designation *"
		name="designation"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.designation}
		/>
		</Grid>
		<Grid item xs={12} md={6}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		id="country"
		placeholder="Country *"
		name="country"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.country}
		/>
		</Grid>
		<Grid item xs={12} md={6}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		id="city"
		placeholder="City *"
		name="city"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.city}
		/>
		</Grid>
		<Grid item xs={12} md={6}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		id="language"
		placeholder="Language *"
		name="language"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.language}
		/>
		</Grid>
		</Grid>
		</Grid>
		</Grid>

		<Divider className={Common.divider} />

		<Grid container spacing={4} className={Common.section}>
		<Grid item xs={12} md={4}><Typography variant="subtitle1">50-word Short Profile</Typography></Grid>
		<Grid item xs={12} md={8}>
		<Grid container spacing={4}>
		<Grid item xs={12} md={12}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		multiline
		rows={3}
		id="profile"
		placeholder="50-word Short Profile *"
		name="profile"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.profile}
		/>
		</Grid>
		</Grid>
		</Grid>
		</Grid>

		<Divider className={Common.divider} />

		<Grid container spacing={4} className={Common.section}>
		<Grid item xs={12} md={4}><Typography variant="subtitle1">Personal Vision for Change in the World</Typography></Grid>
		<Grid item xs={12} md={8}>
		<Grid container spacing={4}>
		<Grid item xs={12} md={12}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		multiline
		rows={3}
		id="stw_vision"
		placeholder="Personal Vision for Change in the World *"
		name="stw_vision"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.stw_vision}
		/>
		</Grid>
		</Grid>
		</Grid>
		</Grid>

		<Divider className={Common.divider} />

		<Grid container spacing={4} className={Common.section}>
		<Grid item xs={12} md={4}><Typography variant="subtitle1">Desired outcomes as part of STW Changemakers</Typography></Grid>
		<Grid item xs={12} md={8}>
		<Grid container spacing={4}>
		<Grid item xs={12} md={12}>
		<TextField
		variant="outlined"
		margin="normal"
		required
		fullWidth
		multiline
		rows={3}
		id="stw_outcome"
		placeholder="Desired outcomes as part of STW Changemakers *"
		name="stw_outcome"
		onChange={handleInputChange}
		className={[Form.textField, Common.raleway]}
		value={values?.stw_outcome}
		/>
		</Grid>
		</Grid>
		</Grid>
		</Grid>

		<Divider className={Common.divider} />

				{/*
				<Grid container spacing={4} className={Common.section}>
	        <Grid item xs={4}><Typography variant="subtitle1">Family members</Typography></Grid>
					<Grid item xs={8}>
						<Grid container spacing={4}>
							<Grid item xs={8}><Typography variant="body2">Family member 1</Typography></Grid>
							<Grid item xs={4}><Typography variant="caption">Disable | Delete</Typography></Grid>
						</Grid>

						<Grid container spacing={4}>
							<Grid item xs={8}><Typography variant="body2">Family member 2</Typography></Grid>
							<Grid item xs={4}><Typography variant="caption">Disable | Delete</Typography></Grid>
						</Grid>

						<Grid container spacing={4}>
							<Grid item xs={8}><Typography variant="body2">Family member 3</Typography></Grid>
							<Grid item xs={4}><Typography variant="caption">Disable | Delete</Typography></Grid>
						</Grid>

						<Grid container spacing={4}>
							<Grid item xs={8}><Button variant="contained" className={styles.button}>+ Add new family member (SGD 9.90 per member)</Button></Grid>
							<Grid item xs={4}></Grid>
						</Grid>
					</Grid>
				</Grid>
			*/}

			{loading ? 
				<Spinner />
				:
				<Grid container spacing={3}>
				<Grid item xs={6} md={6}>
				<Button fullWidth variant="contained" color="secondary" className={styles.raleway} onClick={handleCancel}>Cancel</Button>
				</Grid>
				<Grid item xs={6} md={6}>
				<Button fullWidth variant="contained" color="primary" className={styles.raleway} onClick={() => userUpdateProfile()} disabled={
					!values.fullname ||
					!values.company ||
					!values.designation ||
					!values.country ||
					!values.city ||
					!values.language ||
					!values.profile ||
					!values.stw_vision ||
					!values.stw_outcome
				}>Update</Button>
				</Grid>
				</Grid>
			}
			</Container>

			<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">Cancel profile update</DialogTitle>
			<DialogContent>
			<DialogContentText id="alert-dialog-description">Are you sure you want to cancel profile update? You will lost your unsaved information.</DialogContentText>
			</DialogContent>
			<DialogActions>
			<Button onClick={handleClose} color="primary">Cancel</Button>
			<Button onClick={handleUserLogout} color="primary" autoFocus>Confirm</Button>
			</DialogActions>
			</Dialog>
			</div>
			)
}

export default memo(UserProfileUpdate)
